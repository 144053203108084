<template>
  <div v-if="Object.keys(squareStats).length">
    <div class="vx-row">
      <div
        class="vx-col w-1/2 md:w-1/3"
        :class="isSuper() ? ' xl:w-1/5' : 'xl:w-1/2'"
      >
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="DollarSignIcon"
          :statistic="squareStats.sales.toFixed(2)"
          statisticTitle="إجمالي المبيعات"
          color="success"
        />
      </div>
      <div
        class="vx-col w-1/2 md:w-1/3"
        :class="isSuper() ? ' xl:w-1/5' : 'xl:w-1/2'"
      >
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="GridIcon"
          :statistic="squareStats.products"
          statisticTitle="عدد المنتجات"
          color="success"
        />
      </div>
      <div class="vx-col w-1/2 md:w-1/3 xl:w-1/5">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="UsersIcon"
          :statistic="squareStats.users"
          v-if="squareStats.users"
          statisticTitle="عدد المستخدمين"
          color="warning"
        />
      </div>
      <div class="vx-col w-1/2 md:w-1/3 xl:w-1/5">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="ShoppingBagIcon"
          :statistic="squareStats.stores"
          v-if="squareStats.stores"
          statisticTitle="عدد المتاجر  "
          color="primary"
        />
      </div>
      <div class="vx-col w-1/2 md:w-1/3 xl:w-1/5">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="UserIcon"
          :statistic="squareStats.brands"
          v-if="squareStats.brands"
          statisticTitle="عدد العلامات التجارية"
          color="primary"
        />
      </div>
    </div>
    <div class="vx-row">
      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2"
        v-if="isSuper()"
      >
        <vx-card title="إحصائيات الزيارات" class="text-right">
          <div v-if="squareStats.brandVisitors.top.length">
            <div
              class="w-full vx-row my-4 visitors-box"
              dir="ltr"
              v-for="(top, index) in squareStats.brandVisitors.top"
              :key="index"
            >
              <!-- {{squareStats.brandVisitors.top[0].brand.name}} -->
              <div class="w-3/4 vx-row">
                <div class="custom-number">{{ index + 1 }}</div>
                <div class="w-1/8">
                  <img
                    class="h-16 w-16 top-image"
                    :src="top.brand.logo"
                    alt="top-brand"
                  />
                </div>
                <div class="w-1/2 pl-3 mt-4">
                  <h5 class="text-right">{{ top.brand.name }}</h5>
                </div>
              </div>
              <div>
                <div
                  class="
                    w-5
                    h-5
                    mt-4
                    grid grid-flow-row
                    cursor-pointer
                    justify-center
                    hover:text-primary
                  "
                  @click="pushBrand(top.brand.id)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    class="ml-2"
                    title="عرض تفاصيل"
                  />
                  <span class="text-center">{{ top.counter }}</span>
                </div>
              </div>
            </div>
          </div>
          <EmptyState
            class="force-height-visitors"
            page="زيارات لهذا الشهر"
            v-else
          ></EmptyState>
          <div class="justify-between w-full vx-row splitter-button">
            <div class="flex-col justify-center">
              <h5>عدد زيارات هذا الشهر</h5>
              <h4 class="text-center mt-2 text-primary">{{squareStats.brandVisitors.total}}</h4>
            </div>
            <vs-button @click="viewAllBands"> عرض الجميع </vs-button>
          </div>
        </vx-card>
      </div>
      <!-- CUSTOMERS CHART -->
      <div
        class="vx-col w-full lg:mt-0 mt-base"
        :class="isSuper() ? ' lg:w-1/2' : ' lg:w-full'"
      >
        <vx-card class="p-6" title="إحصائيات الطلبات">
          <div slot="no-body">
            <!-- CHART -->
            <vue-apex-charts
              type="pie"
              height="345"
              class="mt-10 mb-10"
              :options="analyticsData.customersPie.chartOptions"
              :series="customersData.series"
            />
          </div>
        </vx-card>
      </div>

      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2"
        v-if="isSuper() && trxns.length"
      >
        <vx-card title="إحصائيات المعاملات المالية">
          <vue-apex-charts
            type="radialBar"
            height="400"
            width="470"
            :options="radialBarChart.chartOptions"
            :series="trxns"
          ></vue-apex-charts>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueApexCharts from "vue-apexcharts";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import { mapActions } from "vuex";
import EmptyState from "@/views/emptyState.vue";

export default {
  data() {
    return {
      transactionsCount: "",
      series: [],
      trxns: [],
      customersData: {
        series: this.series,
      },
      analyticsData: {
        customersPie: {
          chartOptions: {
            labels: [
              "قيد المراجعة ",
              "معلق",
              "قيد التجهيز",

              "قيد التوصيل ",
              "تم التوصيل ",
              "تم الإلغاء",
              "تم الرفض",
            ],
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: true,
              fontFamily: "Noor, Arial",
              markers: {
                offsetX: 6,
              },
            },

            chart: {
              type: "pie",
              offsetY: 0,
              dropShadow: {
                enabled: false,
                blur: 5,
                left: 1,
                top: 1,
                opacity: 0.2,
              },
              toolbar: {
                show: false,
              },
            },
            stroke: {
              width: 5,
            },
            colors: [
              "#ffb46e",
              "#ffd76e",
              "#af78ff",
              "#50d2a0",
              "#9be169",
              "#6b6c7e",
              "#ff5f5f",
            ],
          },
        },
      },
      radialBarChart: {
        series: this.getTrxns,
        chartOptions: {
          colors: ["#ff5f5f", "#9be169", "#ffb46e"],
          legend: {
            show: true,
            fontFamily: "Noor, Arial",
            markers: {
              offsetX: 6,
            },
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "22px",
                  fontFamily: "Noor, Arial",
                },
                value: {
                  rtl: true,
                  fontSize: "25px",
                },
                total: {
                  show: true,

                  label: "Transactions",
                  formatter: () => {
                    return this.transactionsCount;
                  },
                },
              },
            },
          },
          labels: ["تم الرفض", " مؤكدة", "قيد المراجعة"],
        },
      },
    };
  },
  methods: {
    ...mapActions("HomeStats", [
      "fetchStats",
      "fetchOrderStats",
      "fetchTransactions",
      "fetchCurrencies",
    ]),
    viewAllBands() {
      this.$router.push("/brand-visitors-list");
    },
    pushBrand(id) {
      this.$router.push({ path: "brand-visitors-details", query: { id } });
    },
    getOrderSeries(response) {
      var series = [];
      response.forEach((element) => {
        series.push(element.count);
      });
      return series;
    },
    getLabels(response) {
      var labels = [];
      response.forEach((element) => {
        labels.push(element.statue);
      });
      return labels;
    },
    isSuper() {
      return localStorage.getItem("role") == "super-admin" ? 1 : 0;
    },
    loadHome() {
      this.fetchStats().then(() => {
        this.$vs.loading.close();
      });
      this.fetchOrderStats().then((response) => {
        this.customersData.series = this.getOrderSeries(response.data.data);
      });
      if (this.isSuper())
        this.fetchTransactions().then((response) => {
          this.trxns.push(parseInt(response.cancelled.percentage));
          this.trxns.push(parseInt(response.confirmed.percentage));
          this.trxns.push(parseInt(response.under_review.percentage));
          this.transactionsCount = response.transactions;
        });
    },
  },
  components: {
    StatisticsCardLine,
    VueApexCharts,
    VuePerfectScrollbar,
    EmptyState,
  },
  computed: {
    squareStats() {
      return this.$store.state.HomeStats.stats;
    },
    orderStats() {
      return this.$store.state.HomeStats.order_stats;
    },
    trxnsStats() {
      return this.$store.state.HomeStats.trxn_stats;
    },
    getTrxns() {
      return this.trxns;
    },
    dashboardStatistics() {
      return true;
      // return this.$store.state.DashboardStatistics;
    },
  },
  created() {
    this.$vs.loading();
    this.loadHome();
  },
};
</script>

<style>
.vx-card__title h4 {
  font-size: 26px;
}
.apexcharts-text {
  font-family: "Cairo", sans-serif !important;
}
.custom-number {
  position: absolute;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background: #fff;
  border: 3px solid #c4aea3;
  color: #c4aea3;
  text-align: center;
  font: 32px;
}
.top-image {
  border: 1px solid #c4aea3;
  border-radius: 10px;
}
.visitors-box {
  justify-content: space-around;
  border: 1px solid #af9182;
  padding: 4px;
  border-radius: 10px;
}
.force-height-visitors {
  height: 100% !important;
}
.splitter-button{
  border-top: 1px solid #af9182;
  padding-top: 20px;
}
</style>
