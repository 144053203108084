<template>
    <div class="h-screen flex w-full">
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <img src="@/assets/images/pages/404.png" alt="graphic-404" class="mx-auto mb-1 max-w-full">
            <h1 class="sm:mx-0 mx-4 mb-4 text-2xl d-theme-heading-color">لا يوجد اي {{ page }} حاليا</h1>
            <vs-button v-if="display" size="large" @click="link">إضافة جديد</vs-button>
        </div>
    </div>
</template>

<script>
export default {
props: {
    page :{ 
        type: String,
        required:true,
    },
    link:{
        required:false,
    },
    display:{
        type:Boolean,
        required:false,
    }
}
}
</script>

<style>
.display-none{
display: none;
}
</style>
